import { AnyAction, combineReducers } from 'redux';

import * as a from './tenders.actions';
import { TenderLoadDetail } from 'shared/models/my-loads/loads/tender-load-detail.model';
import { Tender } from 'shared/models/my-loads/tenders/tender.model';
import { getErrorMessage } from 'app/util/util';

export interface TendersState {
  availableLoads: TenderLoadDetail[];
  availableTenders: Tender[];
  fetchError: string;
}

export const availableLoads = (state = [], action: AnyAction) => {
  switch (action.type) {
    case a.FETCH_TENDERS_SUCCESS:
    case a.FETCH_TENDERS_QUIET_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export const availableTenders = (state = [], action) => {
  switch (action.type) {
    case a.FETCH_TENDERS_SUCCESS:
    case a.FETCH_TENDERS_QUIET_SUCCESS:
      return action.payload.reduce((result, load: TenderLoadDetail) => [...result, ...load.tenders], []);
    default:
      return state;
  }
};

export const fetchError = (state = '', action) => {
  switch (action.type) {
    case a.FETCH_TENDERS_FAILURE:
    case a.FETCH_TENDERS_QUIET_FAILURE:
      return getErrorMessage(action.error);
    case a.FETCH_TENDERS_SUCCESS:
    case a.FETCH_TENDERS_QUIET_SUCCESS:
    case a.FETCH_TENDERS:
      return '';
    default:
      return state;
  }
};

export const tendersReducer = combineReducers({availableLoads, availableTenders, fetchError});
