import { Container } from 'typedi';

import { Store } from 'store/index';
import { epic$ } from 'store/epics/root.epics';
import { tendersReducer } from './tenders.reducers';
import { tendersEpic } from './tenders.epics';

const store = Container.get(Store);

store.reducerManager.add('tenders', tendersReducer);
store.dispatch({type: 'init-tenders'});

epic$.next(tendersEpic);
