import { Container } from 'typedi';
import { Action, AnyAction } from 'redux';
import { combineEpics } from 'redux-observable';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

import { fetchTendersQuiet, FETCH_TENDERS, fetchTendersFailure, fetchTendersSuccess,
  START_POLLING_TENDERS, startPollingTenders,
  STOP_POLLING_TENDERS, stopPollingTenders } from './tenders.actions';
import { MyLoadsRepository } from 'app/repositories/my-loads.repository';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { Grants } from 'features/security/grants';
import { LOGOUT } from 'features/security/auth.actions';
import { AppRoute } from 'app/routesEnum';

interface Deps {
  repo: MyLoadsRepository;
}

type TendersEpic<OutputAction extends Action = AnyAction> = NavCarrierEpic<OutputAction, Deps>;

export const fetchTendersEpic: TendersEpic = (action$, state$, {repo}) =>
  action$.ofType(FETCH_TENDERS)
    .filter(() => state$.value.auth.isAuthenticated)
    .filter(() => Grants.TendersGrant(state$.value.auth.user))
    .switchMap(() => repo.getTenders())
    .map(tenders => fetchTendersSuccess(tenders))
    .catch((res: AjaxError) => Observable.of(fetchTendersFailure(res)));

export const pollTendersEpic: TendersEpic = action$ =>
  action$.ofType(START_POLLING_TENDERS)
    .switchMap(() => Observable.interval(60 * 1000)
      .map(fetchTendersQuiet)
      .takeUntil(action$.ofType(STOP_POLLING_TENDERS, LOGOUT))
    );

export const startPollingTendersEpic: TendersEpic = action$ => {
  return action$.ofType<LocationChangeAction>(LOCATION_CHANGE)
    .filter(action => (action.payload.location.pathname.startsWith(AppRoute.TENDERS)))
    .mapTo(startPollingTenders());
};

export const stopPollingTendersEpic: TendersEpic = action$ => {
  return action$.ofType<LocationChangeAction>(LOCATION_CHANGE)
    .filter(action => (!action.payload.location.pathname.startsWith(AppRoute.TENDERS)))
    .mapTo(stopPollingTenders());
};

export const tendersEpic = (action$, store) => combineEpics(
  fetchTendersEpic,
  pollTendersEpic,
  startPollingTendersEpic,
  stopPollingTendersEpic
)(action$, store, {repo: Container.get(MyLoadsRepository)});
